export let icons: any = {
    "ABMClientes_ICON": "suitcase",
    "ABMMensajCli_ICON": "desktop",
    "ABMMensaje_ICON": "envelope-open",
    "ABMUsuariosBO_ICON": "users",
    "ABMVisualizadores_ICON": "newspaper",
    "ABM_COMP_ICON": "sitemap",
    "ABM_PROC_ICON": "share-alt",
    "ABM_SERVICIOS_ICON": "cogs",
    "ABM_SERV_ICON": "",
    "ABM_US_ICON": "users",
    "AdmApps_ICON": "cog",
    "BACKOFFICE_ICON": "",
    "CONEXIONESBO_ICON": "link",
    "CONEXIONES_ICON": "",
    "CONFIGURACIONES_ICON": "",
    "CONFIG_DOCS_ICON": "copy",
    "CONFIG_SEG_ICON": "lock",
    "ClientesMixtos_ICON": "sitemap",
    "ConfigDocE_ICON": "copy",
    "ConfigDocR_ICON": "copy",
    "ConfigNotif_ICON": "bell",
    "ConvUnidDest_ICON": "chevron-right",
    "ConvUnidOrig_ICON": "chevron-left",
    "ConvUnid_ICON": "retweet",
    "CrearFactura_ICON": "",
    "DATOS_EMP_ICON": "building",
    "DOCUMENTOSBO_ICON": "copy",
    "DOCUMENTOS_ICON": "",
    "DatosExtra_ICON": "file-alt",
    "Delegaciones_ICON": "briefcase",
    "DomiciliosRecepAfip_ICON": "building",
    "EVENTOS_ICON": "bolt",
    "EquivalenciasArt_ICON": "barcode",
    "EquivalenciasCli_ICON": "exchange-alt",
    "GrillaFCred_ICON": "copy",
    "ImpresionOD_ICON": "copy",
    "ListaPre_ICON": "list",
    "MAPAS_ICON": "code",
    "MaestroArt_ICON": "list",
    "OPERACIONES_ESP_ICON": "",
    "OperationCompanyM_ICON": "file-alt",
    "PistasAudit_ICON": "user-secret",
    "RecordVencimien_ICON": "calendar",
    "Representaciones_ICON": "briefcase",
    "ResumenTrafDocsEmp_ICON": "chart-bar",
    "SOPORTEAPOYO_ICON": "eye",
    "SeguimientoFCred_ICON": "tachometer-alt",
    "SubidaArchivos_ICON": "cloud-upload-alt",
    "TraficoBO_ICON": "chart-line",
    "VALIDADOC_ICON": "check-circle",
    "VISTAS_ICON": "th",
    "VerTrazaProc_ICON": "bars",
    "trafico_ICON": "chart-line",
    "ABMTemplates_ICON": "file-pdf",
    "GestionSolicNCredito_ICON": "exchange-alt",
}