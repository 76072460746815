import { Component, HostListener, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { Cliente } from '../clientes-mixtos/modelos/Cliente';

import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { isNullOrEmptyOrUndefined } from '../../shared/utils/utils.component';
import { GoogleAnalyticsEventsService } from '../../core/services/google-analytics-events.service';

import { NumericConfig } from '@rxweb/reactive-form-validators/models/config';
import { environment } from '../../../environments/environment';
import { MensajeService } from 'src/app/core/services/mensaje.service';
import { MensajeHTMLView } from '../novedades/models/mensajeHTMLView';


@Component({
  selector: 'app-link-vencido',
  templateUrl: './link-vencido.component.html',
  styleUrls: ['./link-vencido.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LinkVencidoComponent implements OnInit {

  public mensajes: MensajeHTMLView[];

  constructor(
    private translate: TranslateService,
    private router: Router,
    private mensajeService: MensajeService) { }

  ngOnInit(): void {
    this.mensajeService.getMensajeUser_ResourceKey('MsjCritico').subscribe((x: MensajeHTMLView[]) => {
      this.mensajes = x;
    });
  }

  cerrarMensaje(mensaje: MensajeHTMLView):void {
    mensaje.resourceKey = 'CierreCrit'; // Se usa para grabar log de auditoria
    this.mensajeService.cerrarMensajeUsuario(mensaje).subscribe(x => {
      this.mensajes.splice(this.mensajes.indexOf(mensaje),1);
      if(this.mensajes.length === 0){
        if(mensaje.bloqueante){
          // En caso de ser el mensaje de renovacion de abono y cerrarlo. Te saca
          sessionStorage.removeItem('token');
          this.router.navigate(['/']);
        }
        else
          this.router.navigate(['main/home']);
      }
    });
  }
  renovarAbono() {
    this.router.navigate(['/renovacion/datosBasicos']);
  }

}
