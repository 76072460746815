import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterMessage } from 'src/app/core/models/alert-message.interface';
import { ToasterService } from 'src/app/core/services/toaster.service';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToasterComponent implements OnInit {
  
  constructor(private toasterService: ToasterService, private router: Router) { }
  @Input() message: ToasterMessage
  
  ngOnInit(): void {

  }

  closeAlert(id: number | undefined) {
    this.toasterService.closeAlert(id)
  }

  getStyleClass(messageType: string) {
    switch (messageType) {
      case 'Comercial':
        return '--comercial'
      case 'Operativo':
        return '--operativo'
      case 'info':
        return '--info'
    }
  }

  getIcon(messageType: string) {
    switch (messageType) {
      case 'Comercial':
        return 'bullhorn'
      case 'Operativo':
        return 'exclamation-circle'
      case 'info':
        return 'exclamation-circle'
      case 'Renovacion':
        return 'bell'
    }
  }
  renovarAbono() {
    this.router.navigate(['/renovacion/datosBasicos']);
  }

}
