import { Component, HostListener, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { Cliente } from '../clientes-mixtos/modelos/Cliente';

import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { isNullOrEmptyOrUndefined } from '../../shared/utils/utils.component';
import { GoogleAnalyticsEventsService } from '../../core/services/google-analytics-events.service';

import { NumericConfig } from '@rxweb/reactive-form-validators/models/config';
import { environment } from '../../../environments/environment';
import { MensajeService } from 'src/app/core/services/mensaje.service';
import { MensajeHTMLView } from '../novedades/models/mensajeHTMLView';
import { ToasterService } from 'src/app/core/services/toaster.service';


@Component({
  selector: 'app-mensaje-critico',
  templateUrl: './mensaje-critico.component.html',
  styleUrls: ['./mensaje-critico.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MensajeCriticoComponent implements OnInit {

  public mensajes: MensajeHTMLView[];

  constructor(
    private translate: TranslateService,
    private router: Router,
    private totasterService: ToasterService,
    private authService: AuthService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private mensajeService: MensajeService) { }

  ngOnInit(): void {
    this.mensajeService.getMensajeUser_ResourceKey('MsjCritico').subscribe((x: MensajeHTMLView[]) => {

      let userData = this.authService.getUserData();
      var razonSocial = userData?.Cliente.RazonSocial ?? "";
      if(x[0].tipoMensaje == 'Revision') {
        this.googleAnalyticsEventsService.emitEvent('Mensaje Crítico', 'Revision usuario', 'mostar', 0);
        razonSocial = (userData?.Apellido ?? "") + ', ' + (userData?.Nombre ?? "");
      }
        
      // var savedName: any = x.map(e => e.mensaje.replace("{0}", razonSocial));
      x.forEach(e => {
        e.mensaje = e.mensaje.replace("{0}", razonSocial)
        this.googleAnalyticsEventsService.emitEvent('Mensaje Bloqueante' + (e.resourceKey != '' ? ' - Automático' : ' - Manual'), 'Mostrar', e, true);
    });


      this.mensajes = x
    });

    setTimeout(() => {
      var test = document.getElementsByClassName("numero");
      for (let i = 0; i < test.length; i++) {
        test[i].innerHTML = (i + 1).toString()
      }
      var arr: any = document.getElementById("mensaje.mensaje")?.getElementsByTagName("a");
      for (let i = 0; i < arr.length; i++) {
        arr[i].setAttribute('target', '_blank');
      }
    }, 300)

  }

  cerrarMensaje(mensaje: MensajeHTMLView): void {
    this.googleAnalyticsEventsService.emitEvent('Mensaje Bloqueante' + (mensaje.resourceKey != '' ? ' - Automático' : ' - Manual'), 'Cerrar', mensaje, true);
    mensaje.resourceKey = 'CierreCrit'; // Se usa para grabar log de auditoria
    mensaje.mensaje = ''; //Se aplica para no grabar el mensaje en auditoria. Se envia Id en su lugar.
    this.mensajeService.cerrarMensajeUsuario(mensaje).subscribe(x => {
      this.mensajes.splice(this.mensajes.indexOf(mensaje), 1);
      if (this.mensajes.length === 0) {
        if (mensaje.bloqueante) {
          // En caso de ser el mensaje de renovacion de abono y cerrarlo. Te saca
          sessionStorage.removeItem('token');
          this.router.navigate(['/']);
        }
        else {
          this.totasterService.getMessages();
          this.authService.getRecuperarAvisoCambioContrasena().subscribe((respuesta) => {
            respuesta = respuesta;
            if (respuesta.value !== null) {
              var mensaje = this.translate.instant('FALTAN_N_DIAS_VENCIMIENTO_CONTRASENA') + respuesta.value + this.translate.instant('FALTAN_N_DIAS_VENCIMIENTO_CONTRASENA_2');
              var toasterMessage: any = {
                //resourceKey?: string;
                id: 0,
                mensaje: mensaje,
                tipoMensaje: "info",
                bloqueante: false,
                visibleEnCadaLogin: true
              }
              this.totasterService.pushMessage(toasterMessage);
            }
            this.router.navigate(['main/home']);
          });
        }
        ;
      }
    });
  }
  renovarAbono() {
    this.router.navigate(['/renovacion/datosBasicos']);
  }
  revisionUsuario(){
    this.googleAnalyticsEventsService.emitEvent('Mensaje Crítico', 'Revision usuario', 'Realizar revision', 0);
    this.router.navigate(['/main/configuraciones/31/admUsuariosCorporativos'])
  }
  cerrarMensajeRevision(mensaje: MensajeHTMLView): void {
    this.googleAnalyticsEventsService.emitEvent('Mensaje Crítico', 'Revision usuario', 'Ahora no', 0);
    this.cerrarMensaje(mensaje);
  }
}
