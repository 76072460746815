import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import { environment } from 'src/environments/environment';
import { DocumentoExportacion } from 'src/app/pages/documentos-clientes/components/models/DocumentoExportacion';
import { DocumentosClienteService } from 'src/app/pages/documentos-clientes/components/services/documentos-cliente.service';
import { GenericToasterService } from 'src/app/core/services/generic-toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { DocData } from 'src/app/pages/documentos-clientes/components/table-doc/table-doc.component';
import { TipoDocumento } from 'src/app/pages/equivalencia-estructuras/modelos/TipoDocumento';
import { ExportacionDeDocumentosResult } from 'src/app/pages/documentos-clientes/components/models/ExportacionDeDocumentosResult';
import { RecuperarExportacionDocumentos } from 'src/app/pages/documentos-clientes/components/models/RecuperarExportacionDocumentos';
import { DownloadService } from './download.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SolicitudDescarga } from 'src/app/pages/documentos-clientes/components/models/SolicitudDescarga';
import { BusquedaDocumentos } from 'src/app/pages/documentos-clientes/components/models/BusquedaDocumentos';
import { DocumentosMasClientes } from 'src/app/pages/documentos-clientes/components/models/DocumentosMasClientes';
import { GoogleAnalyticsEventsService } from 'src/app/core/services/google-analytics-events.service';
import { ClaveCompuestaView } from '../models/ClaveCompuestaView';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DialogViewDocumentsComponent } from 'src/app/pages/documentos-clientes/components/dialog-view-documents/dialog-view-documents.component';
import { take } from 'rxjs/operators';
import { async } from '@rxweb/reactive-form-validators';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  constructor(
    public dialog: MatDialog,
    private documentosClienteService: DocumentosClienteService,
    private genericToasterService: GenericToasterService,
    private translate: TranslateService,
    private downloadService: DownloadService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private domSanitizer: DomSanitizer
  ) { }

  //#region Public Methods
  public async DescargarDocumentoOnline(documento: DocData): Promise<void> {
    this.googleAnalyticsEventsService.emitEvent('Grilla documentos', 'Ver documento Online', null, true);
    return new Promise((resolve) => {
      this.documentosClienteService.getVerificarDisponibleVisualizadorHash(documento.id, documento.idPais).subscribe((data: boolean) => {
        if (data) {
          let popUp = this.AbrirPopUpViewDocumento();
          var formato = documento.esLadrillo ? "" : "Pdf";
          this.documentosClienteService.postExportarDocumentos(
            [{
              Id: documento.id,
              IdPais: documento.idPais,
              Sentido: documento.sentido
            }],
            [],
            formato
          ).subscribe(async (exportacion: ExportacionDeDocumentosResult) => {
            if (exportacion != null && typeof (exportacion) != undefined) {
              var exportacionDocumento: RecuperarExportacionDocumentos = { id: exportacion.id, attachment: false };
              await this.AbrirDocumentoPopUp(popUp, exportacionDocumento, documento.id);
            }
            resolve();
          },
            error => {
              this.genericToasterService.pushError(this.translate.instant('ERROR_APLICACION'));
            });
        } else {
          this.genericToasterService.pushError(this.translate.instant('CONTENIDO_DOCUMENTO_NO_DISPONIBLE'));
        }
      });
    });
  }

  public async DescargarDocumentoOnlineImagen(documentoVinculado: DocData): Promise<void> {
    this.googleAnalyticsEventsService.emitEvent('Grilla documentos', 'Ver documento Online Image', null, true);
    documentoVinculado.sentido = 'ENVIADO';
    documentoVinculado.esLadrillo = true;
    return new Promise((resolve) => {
      this.documentosClienteService.getVerificarDisponibleVisualizadorHash(documentoVinculado.id, documentoVinculado.idPais).subscribe((data: boolean) => {
        if (data) {
          let popUp = this.AbrirPopUpViewDocumento();
          var formato = documentoVinculado.esLadrillo ? "" : "Pdf";
          this.documentosClienteService.postExportarDocumentos(
            [{
              Id: documentoVinculado.id,
              IdPais: documentoVinculado.idPais,
              Sentido: documentoVinculado.sentido
            }],
            [],
            formato
          ).subscribe(async (exportacion: ExportacionDeDocumentosResult) => {
            if (exportacion != null && typeof (exportacion) != undefined) {
              var exportacionDocumento: RecuperarExportacionDocumentos = { id: exportacion.id, attachment: false };
              await this.AbrirDocumentoPopUp(popUp, exportacionDocumento, documentoVinculado.id);
            }
            resolve();
          },
            error => {
              this.genericToasterService.pushError(this.translate.instant('ERROR_APLICACION'));
            });
        } else {
          this.genericToasterService.pushError(this.translate.instant('CONTENIDO_DOCUMENTO_NO_DISPONIBLE'));
        }
      });
    });
  }
  public async DescargarEtiquetaOnline(documento: DocData): Promise<void> {
    this.googleAnalyticsEventsService.emitEvent('Grilla documentos', 'Ver etiqueta Online', null, true);
    return new Promise((resolve) => {
      this.documentosClienteService.getEtiqueta(documento).subscribe(async (data: string) => {
        if (data) {
          let popUp = this.AbrirPopUpViewDocumento();
          const formato: string = "Pdf";
          var exportacionDocumento: RecuperarExportacionDocumentos = { id: data, attachment: false };
          await this.AbrirDocumentoPopUp(popUp, exportacionDocumento, data);
          resolve();
        } else {
          this.genericToasterService.pushError(this.translate.instant('CONTENIDO_DOCUMENTO_NO_DISPONIBLE'));
        }
      });
    });
  }

  public async DescargarDocumentoHistorial(documentoHistorial: ClaveCompuestaView): Promise<void> {
    this.googleAnalyticsEventsService.emitEvent('Informacion adicional', 'Ver documento historial', null, true);
    return new Promise((resolve) => {
      this.documentosClienteService.getVerificarDisponibleVisualizador(documentoHistorial.Id, documentoHistorial.IdPais).subscribe((data: boolean) => {
        if (data) {
          let popUp = this.AbrirPopUpViewDocumento();
          var formato = "";
          this.documentosClienteService.postExportarDocumentosHistorial(
            [{
              Id: documentoHistorial.Id,
              IdPais: documentoHistorial.IdPais,
              Sentido: 'ENVIADO'
            }],
            [],
            formato
          ).subscribe(async (exportacion: ExportacionDeDocumentosResult) => {
            if (exportacion != null && typeof (exportacion) != undefined) {
              var exportacionDocumento: RecuperarExportacionDocumentos = { id: exportacion.id, attachment: false };
              await this.AbrirDocumentoPopUp(popUp, exportacionDocumento, documentoHistorial.Id);
            }
            resolve();
          },
            error => {
              this.genericToasterService.pushError(this.translate.instant('ERROR_APLICACION'));
            });
        } else {
          this.genericToasterService.pushError(this.translate.instant('CONTENIDO_DOCUMENTO_NO_DISPONIBLE'));
        }
      });
    });
  }

  public async ExportarDocumentoOnline(documento: DocData): Promise<void> {
    return new Promise((resolve) => {
      this.documentosClienteService.getVerificarDisponibleVisualizadorHash(documento.id, documento.idPais).subscribe((data: boolean) => {
        if (data) {
          this.genericToasterService.pushSuccess(this.translate.instant('ExportarXLS'));
          documento.sentido = (documento.sentido == 'ENVIADO' || documento.sentido == 'E') ? 'E' : 'R';
          this.documentosClienteService.postExportarDocumentos(
            [{
              Id: documento.id,
              IdPais: documento.idPais,
              Sentido: documento.sentido
            }],
            [],
            "Excel"
          ).subscribe(async (exportacion: ExportacionDeDocumentosResult) => {
            if (exportacion != null && typeof (exportacion) != undefined) {
              var exportacionDocumento: RecuperarExportacionDocumentos = { id: exportacion.id, attachment: false };
              await this.DescargarDocumentoSinAbrirSolapa(exportacionDocumento);
            }
            resolve();
          },
            error => {
              this.genericToasterService.pushError(this.translate.instant('ERROR_APLICACION'));
            });
        } else {
          this.genericToasterService.pushError(this.translate.instant('CONTENIDO_DOCUMENTO_NO_DISPONIBLE'));
        }
      });
    });
  }

  public async DocumentoRequiereGestor(documento: DocData): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let tiposDocumentos: TipoDocumento[] = [];
      this.downloadService.getConfiguracionDeTiposDocumentoParaGestor().subscribe(
        (data: TipoDocumento[]) => {
          if (data.length > 0) {
            tiposDocumentos = data;
            resolve(tiposDocumentos.filter(e => e.resourceKey === documento.tipoDoc).length > 0);
          }
        },
        error => {
          this.genericToasterService.pushError(this.translate.instant('ERROR_APLICACION'));
          reject('Error');
        });
    }
    );
  }

  public DescargarDocumentoPorGestor(doc: DocData) {
    this.documentosClienteService.getVerificarDisponibleVisualizadorHash(doc.id, doc.idPais).subscribe((existe: boolean) => {
      if (existe) {
        const solicitudDescarga = new SolicitudDescarga();
        solicitudDescarga.filtrosDeBusqueda = new BusquedaDocumentos();
        solicitudDescarga.filtrosDeBusqueda.empresasDelUsuario = [];
        solicitudDescarga.documentosSeleccionados = new DocumentosMasClientes();
        solicitudDescarga.documentosSeleccionados.formato = 'ZIP';
        solicitudDescarga.documentosSeleccionados.clientesHolding = [];
        solicitudDescarga.documentosSeleccionados.documentos = [doc];
        this.downloadService.postExportarDocumentos(solicitudDescarga).subscribe(
          (data: any) => {
            if (data.value == "Ok") {
              this.genericToasterService.pushSuccess(this.translate.instant("LA_DESCARGA_ESTA_EN_PROCESO"));
            }
            else {
              var mensaje = doc.estado == "NoDisponible" ? "CONTENIDO_DOCUMENTO_NO_DISPONIBLE_EXT" : "CONTENIDO_DOCUMENTO_NO_DISPONIBLE";
              this.genericToasterService.pushWarning(this.translate.instant(mensaje));
            }
          },
          error => {
            this.genericToasterService.pushError(this.translate.instant('ERROR_APLICACION'));
          });
      } else {
        this.genericToasterService.pushError(this.translate.instant('CONTENIDO_DOCUMENTO_NO_DISPONIBLE'));
      }
    },
      error => {
        this.genericToasterService.pushError(this.translate.instant('ERROR_APLICACION'));
      });
  }

  public async DescargarDocumentoSinAbrirSolapa(exportacion: RecuperarExportacionDocumentos): Promise<void> {
    return new Promise((resolve) => {
      var attach = (exportacion.attachment) ? exportacion.attachment : false;
      this.documentosClienteService.getRecuperarExportacionDocumentos(exportacion.id).subscribe((data: DocumentoExportacion) => {
        this.MostrarDocumentoSinVentana(data, attach);
        resolve();
      },
        (error) => {
          this.genericToasterService.pushError(this.translate.instant('ERROR_APLICACION'));
        });
    });
  }

  SanitizeURL(url: string): SafeResourceUrl {
    var urlSanitizada = this.domSanitizer.bypassSecurityTrustResourceUrl(url)
    return urlSanitizada;
  }

  //#endregion

  //#region Private Methods
  private AbrirPopUpViewDocumento(): MatDialogRef<DialogViewDocumentsComponent> {
    const dialogRef = this.dialog.open(DialogViewDocumentsComponent, {
      width: '95%',
      height: '97%',
      maxWidth: '100%',
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe(
      async result => {
      }
    );
    return dialogRef;
  }

  private async AbrirDocumentoPopUp(popUp: MatDialogRef<DialogViewDocumentsComponent>, datos: RecuperarExportacionDocumentos, idSobre: string): Promise<void> {
    return new Promise((resolve) => {
      var attach = (datos.attachment) ? datos.attachment : false;
      this.documentosClienteService.getRecuperarExportacionDocumentos(datos.id).subscribe((data: DocumentoExportacion) => {
        data.nombreTemporal = data.nombreTemporal == null ? "" : data.nombreTemporal;
        var url = environment.apiUrl + "/DocumentosCliente/VisualizarDocumento?guid=" + data.nombreTemporal +
          "&filename=" + this.ReemplazarCaracteresEspeciales(data.nombre) +
          "&tipoContenido=" + data.tipoContenido +
          "&m=" + !this.IsMobile() +
          "&attach=" + attach;
        popUp.componentInstance.setDoc(this.SanitizeURL(url));
        resolve();
      },
        error => {
          this.genericToasterService.pushError(this.translate.instant('ERROR_APLICACION'));
          popUp.close();
        });
    });
  }

  public MostrarDocumentoSinVentana(documento: DocumentoExportacion, attach: boolean) {
    documento.nombreTemporal = documento.nombreTemporal == null ? "" : documento.nombreTemporal;
    this.documentosClienteService.getDescargaDocumento(
      documento.nombreTemporal,
      this.ReemplazarCaracteresEspeciales(documento.nombre),
      documento.tipoContenido,
      !this.IsMobile(),
      attach
    ).subscribe((data: ArrayBuffer) => {

      const file = new File([data], this.ReemplazarCaracteresEspeciales(documento.nombre), {
        type: 'application/zip',
      });
      let blobURL = window.URL.createObjectURL(file);

      let a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute('style', 'display:none');
      a.setAttribute('href', blobURL);
      a.setAttribute('download', this.ReemplazarCaracteresEspeciales(documento.nombre));
      a.click();
      URL.revokeObjectURL(a.href);
      a.remove();
    },
      (err) => {
        this.genericToasterService.pushError(this.translate.instant('ERROR_APLICACION'));
      });
  }

  private IsMobile(): boolean {
    var isMobile = false;
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) isMobile = true;
    return isMobile;
  }

  private ReemplazarCaracteresEspeciales(entrada: string) {
    var caracteres = ['#'];
    _.each(caracteres, function (caracter) {
      var rCaracter = new RegExp(caracter, 'g');
      var n = entrada.replace(rCaracter, '_');
      entrada = n;
    });
    return entrada;
  }
  //#endregion
}