import { Inject, Injectable } from '@angular/core'
import { AuthService } from '../services/auth.service'
import { ActivatedRouteSnapshot, CanActivate, Data, Router } from '@angular/router'
import { environment } from 'src/environments/environment';
import { DOCUMENT, PathLocationStrategy } from '@angular/common';
import { RedirectHelper } from '../helpers/redirect.helper';


interface RouteRoleData {
  requiredRole: string
}

interface Route extends ActivatedRouteSnapshot {
  data: Data | RouteRoleData
}

@Injectable({
  providedIn: 'root',
})
export class RoleGuardService implements CanActivate {

  constructor(private authService: AuthService, private router: Router, @Inject(DOCUMENT) private document: Document,
    private redirectHelper: RedirectHelper
  ) { }

  canActivate(route: Route): boolean {
    const { requiredRole } = route.data
    let legacyURL = `${environment.url}/index.html#`;
    if (!this.authService.isAuthenticated()) {
      // @todo redirect to login page
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('ClientesHolding');
      sessionStorage.clear();
      // guardo el href para hacer la redireccion cuando estemos logueados
      this.redirectHelper.setRedirectUrlParams(route);
      this.router.navigate(['login']);

      //document se agrega para poder hacer la rediccion al sitio legacy
      //cuando este activo el login actual hay que quitar esto y habilitar por this.router.navigate
      //this.document.location.href = legacyURL
      return false
    }

    if (!this.authService.hasRole(requiredRole)) {
      // @todo redirect to "unauthorized" error page if any
      // this.router.navigate(['unauthorized'])
      return false
    }

    if (sessionStorage.getItem("pm") != undefined) {
      let ruta = route.url.map(x => x.path).join('/');
      var paginas = JSON.parse(sessionStorage.getItem("pm") || '');

      // parche: algunas rutas no empiezan con /main le agrego la barra
      if (ruta.startsWith('main/')) {
        ruta = '/' + ruta;
      }

      paginas?.forEach((item: any, index: number) => {
        paginas[index] = "/main" + item;
      });
      let dir = ruta.match('main') ? ruta : ('/main/' + ruta);
      if (paginas?.includes(dir))
        return true;
      else {

        let redireccionar = ruta.match('/main') ? ruta.replace('/main', '') : ruta;
        this.document.location.href = legacyURL + redireccionar;
        return false;
      }

    }

    return true
  }

}
