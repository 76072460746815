import { Component, OnInit } from '@angular/core';
import { SeguimientoFacturaCreditoService } from './servicios/seguimiento-factura-credito.service';
import { DatosFacturaCredito } from './modelos/datos-factura-credito';
import { DatosExtraFacturaCredito } from './modelos/datos-extra-factura-credito';
import { Color } from 'ng2-charts';
import { GenericToasterService } from 'src/app/core/services/generic-toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { BusquedaDocumentos } from '../consulta-facturas-credito/models/Busqueda/BusquedaDocumentos';
import { TableFCredDataService } from '../consulta-facturas-credito/services/table-fcred-data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-seguimiento-factura-credito',
  templateUrl: './seguimiento-factura-credito.component.html',
  styleUrls: ['./seguimiento-factura-credito.component.scss']
})
export class SeguimientoFacturaCreditoComponent implements OnInit {
  datosFacturaCredito: DatosFacturaCredito[] = [];
  datosFacturaCreditoDias: DatosFacturaCredito | undefined;
  datosFacturaCreditoTacitasExpresas: DatosFacturaCredito | undefined;
  datosFacturaCreditoSCAADC: DatosFacturaCredito[] | undefined = [];
  datosFacturaCreditoSelected: DatosFacturaCredito[] = [];
  datosExtraFacturaCredito: DatosExtraFacturaCredito[] = [];
  ultimaFecha: DatosExtraFacturaCredito;
  opcionesPermitidas: string[] = ['IndTotNoVenc', 'IndNoExisteERP', 'IndProvNoHab'];
  opcionSeleccionada: DatosFacturaCredito | undefined;
  totalFacturas: number = 0;

  constructor(private seguimientoService: SeguimientoFacturaCreditoService,
    private genericToasterService: GenericToasterService,
    private router: Router,
    private tableFCredDataService: TableFCredDataService,
    private translate: TranslateService) { }

  public doughnutChartData: number[] = [1, 1, 1];
  public doughnutChartType: string = 'doughnut';
  public doughnutChartLabels: string[] = ['', '', ''];
  public doughnutChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: 'bottom', // Colocar la leyenda debajo del gráfico
      labels: {
        fontSize: 10, // Tamaño de la fuente de la leyenda
        usePointStyle: true // Utilizar estilos de punto para los elementos de la leyenda
      }
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: { index: string | number; }, data: { labels: { [x: string]: any; }; }) {
          return data.labels[tooltipItem.index]; // Devuelve solo la etiqueta, no el valor
        }
      }
    }
  };

  public doughnutChartColors: Color[] = [
    { backgroundColor: ['#D3D3D3', '#A9A9A9', '#c44ace'] },
  ];

  ngOnInit(): void {
    this.recuperarDatosIndicadoresFacturaCredito();
  }

  recuperarDatosIndicadoresFacturaCredito() {
    this.seguimientoService.recuperarDatosIndicadoresFacturaCredito()
      .subscribe({
        next: (data: DatosFacturaCredito[]) => {
          this.datosFacturaCredito = data;
          this.datosFacturaCreditoSelected = data.filter(opt => this.opcionesPermitidas.includes(opt.resourceKey));
          this.opcionSeleccionada = this.datosFacturaCreditoSelected.find(opt => opt.resourceKey === 'IndTotNoVenc');
          this.totalFacturas = this.datosFacturaCredito.filter(opt => ['IndConf1', 'IndConf2', 'IndVencMan'].includes(opt.resourceKey)).reduce((suma, objeto) => {
            const cantidadNumerica = Number(objeto.cantidad);
            return suma + (isNaN(cantidadNumerica) ? 0 : cantidadNumerica);
          }, 0);
          this.onSeleccionDays('IndVencMan');
          this.onSeleccionExpTac('IndAceptadas');
          this.onSeleccionSCAADC('IndSCA');
          this.doughnutChartData = [parseInt(this.datosFacturaCredito.find(x => x.resourceKey == 'IndVencMan')?.cantidad!), parseInt(this.datosFacturaCredito.find(x => x.resourceKey == 'IndConf1')?.cantidad!), parseInt(this.datosFacturaCredito.find(x => x.resourceKey == 'IndConf2')?.cantidad!)];
          this.recuperarDatosExtraIndicadoresFacturaCredito();
        },
        error: (error: any) => {
          console.error('Error fetching data:', error);
        }
      });
  }

  recuperarDatosExtraIndicadoresFacturaCredito() {
    this.seguimientoService.recuperarDatosExtraIndicadoresFacturaCredito()
      .subscribe({
        next: (data: DatosExtraFacturaCredito[]) => {
          this.datosExtraFacturaCredito = data;
          this.ultimaFecha = this.datosExtraFacturaCredito.find(x => x.resourceKey === 'FechaUltActFCE')!;
          this.doughnutChartLabels = [this.translate.instant('MAÑANA_UPPER') + ": " + this.datosFacturaCredito.find(x => x.resourceKey === 'IndVencMan')?.cantidad, this.datosExtraFacturaCredito.find(x => x.resourceKey === 'DiasIndConf1')?.valor + " " + this.translate.instant('DIAS_UPPER') + ": " + this.datosFacturaCredito.find(x => x.resourceKey === 'IndConf1')?.cantidad, this.datosExtraFacturaCredito.find(x => x.resourceKey === 'DiasIndConf2')?.valor + " " + this.translate.instant('DIAS_UPPER') + ": " + this.datosFacturaCredito.find(x => x.resourceKey === 'IndConf2')?.cantidad];

        },
        error: (error: any) => {
          console.error('Error fetching data:', error);
        }
      });
  }

  onSeleccionDays(days: string) {
    console.log(days);
    switch (days) {
      case "IndConf1":
        this.datosFacturaCreditoDias = this.datosFacturaCredito.find(opt => opt.resourceKey === 'IndConf1')!;
        this.doughnutChartColors = [{ backgroundColor: ['#D3D3D3', '#c44ace', '#A9A9A9'] }];
        break;
      case "IndConf2":
        this.datosFacturaCreditoDias = this.datosFacturaCredito.find(opt => opt.resourceKey === 'IndConf2')!;
        this.doughnutChartColors = [{ backgroundColor: ['#D3D3D3', '#A9A9A9', '#c44ace'] }];
        break;
      case "IndVencMan":
        this.datosFacturaCreditoDias = this.datosFacturaCredito.find(opt => opt.resourceKey === 'IndVencMan')!;
        this.doughnutChartColors = [{ backgroundColor: ['#c44ace', '#A9A9A9', '#D3D3D3'] }];
        break;
      default:
        this.datosFacturaCreditoDias = this.datosFacturaCredito.find(opt => opt.resourceKey === 'IndVencMan')!;
        this.doughnutChartColors = [{ backgroundColor: ['#c44ace', '#A9A9A9', '#D3D3D3'] }];
        break;
    }
  }

  OnClickRecuperarDatos() {
    console.log(this.datosFacturaCreditoDias);
    this.genericToasterService.pushSuccess("LA_DESCARGA_ESTA_EN_PROCESO");
    this.seguimientoService.RecuperarDatosExportacionCSV(this.datosFacturaCreditoDias?.idDocumentoSobre, this.datosFacturaCreditoDias?.idPaisDocumentoSobre).subscribe((response: any) => {

      let fileName = response.headers.get('fileName');
      const file = new File([response.body], fileName, { type: response.body.type });

      let blobURL = window.URL.createObjectURL(file);

      let a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute('style', 'display:none');
      a.setAttribute('href', blobURL);
      a.setAttribute('download', fileName);
      a.click();
      URL.revokeObjectURL(a.href);
      a.remove();

    },
      (err: any) => {
        this.genericToasterService.pushError(this.translate.instant('ERROR_APLICACION'));
      });
  }

  //merge con la de arriba
  OnClickRecuperarDatosTotal() {
    console.log(this.datosFacturaCreditoDias);
    this.genericToasterService.pushSuccess("LA_DESCARGA_ESTA_EN_PROCESO");
    this.seguimientoService.RecuperarDatosExportacionCSV(this.opcionSeleccionada?.idDocumentoSobre, this.opcionSeleccionada?.idPaisDocumentoSobre).subscribe((response: any) => {

      let fileName = response.headers.get('fileName');
      const file = new File([response.body], fileName, { type: response.body.type });

      let blobURL = window.URL.createObjectURL(file);

      let a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute('style', 'display:none');
      a.setAttribute('href', blobURL);
      a.setAttribute('download', fileName);
      a.click();
      URL.revokeObjectURL(a.href);
      a.remove();

    },
      (err: any) => {
        this.genericToasterService.pushError(this.translate.instant('ERROR_APLICACION'));
      });
  }

  OnClickRecuperarPoroveedoresHabituales() {
    this.genericToasterService.pushSuccess("LA_DESCARGA_ESTA_EN_PROCESO");
    this.seguimientoService.RecuperarProveedoresHabituales().subscribe((response: any) => {

      let fileName = response.headers.get('fileName');
      const file = new File([response.body], fileName, { type: response.body.type });

      let blobURL = window.URL.createObjectURL(file);

      let a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute('style', 'display:none');
      a.setAttribute('href', blobURL);
      a.setAttribute('download', fileName);
      a.click();
      URL.revokeObjectURL(a.href);
      a.remove();

    },
      (err: any) => {
        this.genericToasterService.pushError(this.translate.instant('ERROR_APLICACION'));
      });
  }


  esADC(): boolean | undefined {
    return this.datosFacturaCreditoSCAADC?.some(opt => opt.resourceKey === 'IndInformadas' || opt.resourceKey === 'IndNoInformadas');
  }

  onSeleccionDiasShow(exp: string): string | undefined {
    return this.datosExtraFacturaCredito.find(x => x.resourceKey === exp)?.valor
  }

  onSeleccionExpTac(opcion: string) {
    switch (opcion) {
      case "IndAceptExp":
        this.datosFacturaCreditoTacitasExpresas = this.datosFacturaCredito.find(opt => opt.resourceKey === 'IndAceptExp')!;
        break;
      default:
        this.datosFacturaCreditoTacitasExpresas = this.datosFacturaCredito.find(opt => opt.resourceKey === 'IndAceptadas')!;
        break;
    }
  }

  onSeleccionSCAADC(opcion: string) {
    switch (opcion) {
      case "IndADC":
        this.datosFacturaCreditoSCAADC = this.datosFacturaCredito
          .filter(opt => opt.resourceKey === 'IndNoInformadas' || opt.resourceKey === 'IndInformadas')
          .sort((a, b) => {
            if (a.resourceKey === 'IndInformadas' && b.resourceKey === 'IndNoInformadas') {
              return -1;
            } else if (a.resourceKey === 'IndNoInformadas' && b.resourceKey === 'IndInformadas') {
              return 1;
            } else {
              return 0;
            }
          });
        break;
      default:
        this.datosFacturaCreditoSCAADC = this.datosFacturaCredito.filter(opt => opt.resourceKey === 'IndSCA');
        break;
    }
  }

  onADCTitulo(item: DatosFacturaCredito): string {
    let titulo = '';
    if (this.esADC()) {
      if (item.resourceKey === 'IndInformadas') {
        titulo = this.translate.instant('INFORMADAS A CNV');
      }
      else if (item.resourceKey === 'IndNoInformadas') {
        titulo = this.translate.instant('NO INFORMADAS A CNV');
      }
    }
    return titulo;
  }

  ConsultaFCRED() {
    let busqueda: BusquedaDocumentos = new BusquedaDocumentos();

    busqueda.tipoFecha = "FECHA_DE_VTO_ACEPTACION";
    switch (this.datosFacturaCreditoDias?.resourceKey) {
      case "IndConf1":
        busqueda.fechaDesde = new Date(new Date().getTime());
        busqueda.fechaHasta = new Date(new Date().getTime() + (parseInt(this.onSeleccionDiasShow('DiasIndConf1')!) * 24 * 60 * 60 * 1000));
        break;
      case "IndConf2":
        busqueda.fechaDesde = new Date(new Date().getTime());
        busqueda.fechaHasta = new Date(new Date().getTime() + (parseInt(this.onSeleccionDiasShow('DiasIndConf2')!) * 24 * 60 * 60 * 1000));
        break;
      case "IndVencMan":
        busqueda.fechaDesde = new Date(new Date().getTime());
        busqueda.fechaHasta = new Date(new Date().getTime() + (1 * 24 * 60 * 60 * 1000));
        break;
      default:
        busqueda.fechaDesde = new Date(new Date().getTime());
        busqueda.fechaHasta = new Date(new Date().getTime());
        break;
    }
    busqueda.estadosCtaCte = [1];
    busqueda.pantalla = "FCRED";

    this.tableFCredDataService.setSearchFilters(busqueda);
    this.router.navigate(['/main/operacionesEspeciales/2075/ConsultaFacturaCreditoMiPyme']);
  }

  public onClickAyudaSeguimientoFcred() {
    window.open(this.translate.instant("instructivo_seguimiento_fcred"), 'HelpCon');
  }

  ConsultaFCRED_TOP() {
    let busqueda: BusquedaDocumentos = new BusquedaDocumentos();
    
    busqueda.tipoFecha = "FECHA_DE_VTO_ACEPTACION";
    const aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 2);
    busqueda.fechaDesde = new Date();
    busqueda.fechaHasta = aYearFromNow;
    busqueda.pantalla = "FCRED";
    if (this.opcionSeleccionada?.resourceKey == 'IndProvNoHab') {
      busqueda.estadosCtaCte = [1, 3, 5]; // evitar hardcodeo por ids!!!!!
      busqueda.existeEnERP = null;
      busqueda.existeEnK360 = null;
      busqueda.esProveedorHabitual = false;
    }
    if (this.opcionSeleccionada?.resourceKey == 'IndNoExisteERP') {
      busqueda.estadosCtaCte = [1, 3, 5]; // evitar hardcodeo por ids!!!!!
      busqueda.existeEnERP = false;
      busqueda.existeEnK360 = null;
      busqueda.esProveedorHabitual = null;
    }
    this.tableFCredDataService.setSessionFilters(busqueda);
    this.router.navigate(['/main/operacionesEspeciales/2075/ConsultaFacturaCreditoMiPyme']);
  }
}
