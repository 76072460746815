<div class="header__login">
  <a
    href="https://www.planexware.com/"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img height="15" src="assets/planexware-white.svg" alt="Planexware"
  /></a>
</div>
<div class="background">
  <div class="card-wrapper">
    <div *ngFor="let mensaje of mensajes" class="c-wrapper">
      <mat-card>
        <div [innerHtml]="mensaje.mensaje" id="mensaje.mensaje"></div>
        <div *ngIf="mensaje.tipoMensaje == 'Renovacion'">
          <button
            type="button"
            class="btn critical-btn-alert"
            (click)="renovarAbono()"
            translate
          >
            RENOVAR_ABONO
          </button>
          <button
            type="button"
            class="btn btn-link link-cancelar"
            (click)="cerrarMensaje(mensaje)"
            translate
          >
            AHORA_NO
          </button>
        </div>
        <div *ngIf="mensaje.tipoMensaje == 'Revision'" style=" display: flex !important; justify-content: right !important;flex-direction: row-reverse !important;">

          <button
            type="button"
            class="btn critical-btn-alert-rev"
            (click)="revisionUsuario()"
          >
            {{ ("GESTIONAR_USUARIO" | translate).toUpperCase()}}
          </button>

          <button
          type="button"
          class="btn btn-link-rev link-cancelar"
          (click)="cerrarMensajeRevision(mensaje)"
          translate
        >
        {{ ("AHORA_NO" | translate).toUpperCase()}}
        </button>

        </div>
        <button
          *ngIf="mensaje.tipoMensaje != 'Renovacion' && mensaje.tipoMensaje != 'Revision'"
          type="button"
          (click)="cerrarMensaje(mensaje)"
          translate
          class="cerrar"
        >
          CERRAR
        </button>
      </mat-card>
    </div>
  </div>
</div>
