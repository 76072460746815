import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MegabytesPipe } from './megabytes.pipe';
import { JoinTranslatePipe } from './JoinTranslate.pipe';
import { FormatoMoneda } from './formato-moneda.pipe'
import { DocHistorialPipe } from './doc-historial.pipe';
import { FormatoMensajesDocumentosPipe } from './formato-mensajes-documentos.pipe'



@NgModule({
  declarations: [MegabytesPipe,JoinTranslatePipe,FormatoMoneda,DocHistorialPipe, FormatoMensajesDocumentosPipe],
  imports: [
    CommonModule,
    TranslateModule.forChild()
  ],
  exports: [MegabytesPipe,JoinTranslatePipe,FormatoMoneda,DocHistorialPipe, FormatoMensajesDocumentosPipe]
})
export class PipesModule { }
