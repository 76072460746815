<div class="vertical-menu mt-3" style="overflow-x: hidden;">
  <div *ngFor="let item of menu">
    <div *ngIf="
    !item.disabled 
    || 
    (item.disabled && item.esEspecifico && authService.userdata.ConectadoComo) 
    || 
    (item.disabled && !item.esEspecifico && authService.userdata.ConectadoComo)
    ||
    (item.disabled && !item.esEspecifico && !authService.userdata.ConectadoComo)
    "
    class="vertical-menu__item"
    [ngClass]="{'vertical-menu__item--disabled':
    (item.disabled && !authService.userdata.ConectadoComo)}" [routerLink]="(!item.disabled || authService.userdata.ConectadoComo) ? ['/'+item.url] : []">
      <div class="item__icon">
        <fa-icon [icon]="['fas', getIcon(item.resourceKey)]" transform="grow-10"></fa-icon>
      </div>
      <div class="item__content" id="item.id">
        <h3 class="item__content__title">
          {{ item.resourceKey | translate }}
          <span *ngIf="authService.userdata.ConectadoComo && item.disabled">
            <fa-icon
              class="item__user__icon"
              [icon]="['fas', 'user-times']"
              transform="shrink-2"
              ngbPopover="{{'MSG_ITEMS_MENU_POPOVER' | translate}}"
              triggers="mouseenter:mouseleave" placement="right">
            </fa-icon>
          </span>
        </h3>
        <div class="item__content__description"
        style="width: 210%; overflow-x: auto;"
        >
          {{ item.resourceKey + '_DESC' | translate }}.
        </div>
      </div>
      <div class="disabled-message">
        <span>
          {{ 'CONTRATO_SERVICIO'| translate }}
          <a class="disabled-message__link" href="tel:+541177018277">(+5411) 7701 8277</a>
        </span>
      </div>
    </div>
  </div>
</div>
