<app-accesos-directos-pp *ngIf="!esUsuarioPLX() || estaConectadoComo()"></app-accesos-directos-pp>

<div
  [hidden]="!tieneComunidad"
  class="Comunidad__header d-flex"
  [ngClass]="{ comunidad_is_hidden: !tieneComunidad }"
>
  <h2 class="Comunidad__title" [hidden]="!tieneComunidad">
    {{ "Mi_Comunidad_Titulo" | translate }}
  </h2>
  <a
    href="https://ayuda.planexware.net/instructivos/comunidades_k360.pdf"
    target="comunidad"
    [hidden]="!tieneComunidad"
  >
    <mat-icon
      style="color: black; margin-top: 0.5rem; padding-left: 0.2rem"
      matTooltip="{{ COMUNIDAD_TOOLTIP | translate }}"
      matTooltipClass="Comunidad__tooltip"
      matTooltipPosition="right"
      >help</mat-icon
    >
  </a>
  <mat-icon
    style="cursor: pointer; margin-left: 5px"
    (click)="toggleComunidad()"
    [hidden]="!tieneComunidad || !expandirComunidad"
    >keyboard_arrow_up</mat-icon
  >
  <mat-icon
    style="cursor: pointer; margin-left: 5px"
    (click)="toggleComunidad()"
    [hidden]="!tieneComunidad || expandirComunidad"
    >keyboard_arrow_down</mat-icon
  >
</div>
<div class="chart-wrapper"   [hidden]="!(tieneComunidad && expandirComunidad)">
  <mat-card class="left-chart">
    <mat-spinner class="spinner" *ngIf="spinner"></mat-spinner>
    <div
      class="Donut_Vacio"
      *ngIf="!this.conexionesEstadoSubject.value && !spinner"
    >
      <mat-icon>cached</mat-icon>
      <h2>{{ "Donut_Grafico_Vacio" | translate }}</h2>
      <div class="h3-wrapper">
        <h3>{{ "Donut_Grafico_Vacio_Descrip1" | translate }}</h3>
        <h3>{{ "Donut_Grafico_Vacio_Descrip2" | translate }}</h3>
      </div>
    </div>
    <div *ngIf="this.conexionesEstadoSubject.value && !spinner">
      <h3>{{ "Empresas" | translate }}</h3>
      <span class="total-empresas"
        >{{ "TOTAL" | translate }}: {{ totalEmpresas }}
        {{ "Empresas" | translate }}</span
      >
      <!-- GRAFICO TORTA -->

      <!-- <ngx-charts-advanced-pie-chart
      [(results)]="donaData"
      [scheme]="colorScheme"
      [gradient]="true"
      [label]="label">
      </ngx-charts-advanced-pie-chart> -->

      <canvas
        baseChart
        [data]="pieChartData"
        [labels]="pieChartLabels"
        [chartType]="pieChartType"
        [options]="pieChartOptions"
        [plugins]="pieChartPlugins"
        [legend]="pieChartLegend"
        [colors]="pieChartColors"
      >
      </canvas>

      <h4>
        {{ "Su_Empresa_Invito_a" | translate }} <b>{{ totalEmpresas }}</b>
        {{ "Empresas_De_Las_Cuales" | translate }} <b>{{ noAceptaron }}</b>
        {{ "No_Han_Aceptado_Aun" | translate }}
        <a [routerLink]="['/main/conexiones/38']">{{
          "Su_Panel_De_Conexiones" | translate
        }}</a>
        <m >{{
          "Su_Panel_De_Conexiones" | translate
        }}</m>
      </h4>
    </div>
  </mat-card>
  <mat-card class="barras-card">
    <mat-spinner class="spinner" *ngIf="spinner"></mat-spinner>
    <!-- GRAFICO BARRAS -->
    <div
      class="Barras_Vacio"
      *ngIf="!this.indicadoresEstadoSubject.value && !spinner"
    >
      <mat-icon style="width: auto; font-size: 64px;">file_copy</mat-icon>
      <h2>{{ "Bar_Grafico_Vacio" | translate }}</h2>
      <h3>{{ "Bar_Grafico_Vacion_Descrip" | translate }}</h3>
    </div>
    <div
      class="divBarras"
      *ngIf="this.indicadoresEstadoSubject.value && !spinner"
    >
      <ngx-slick-carousel
        class="carousel"
        #slickModal="slick-carousel"
        [config]="slideConfig"
      >
        <div
          ngxSlickItem
          *ngFor="let element of barlistBehavior.value; let i = index"
          class="slide"
        >
          <div style="display: block;  margin: auto">
            <ngx-charts-bar-vertical-stacked
              [view]="view"
              [scheme]="colorScheme"
              [results]="element"
              [gradient]="gradient"
              [xAxis]="showXAxis"
              [yAxis]="showYAxis"
              [legend]="showLegend"
              [legendTitle]="showLegendTitle"
              [animations]="animations"
              [showXAxisLabel]="showXAxisLabel"
              [xAxisLabel]="xAxisLabel"
              [barPadding]="200"
              [legendPosition]="['right', 'below']"
              (select)="onSelect($event)"
            >
            </ngx-charts-bar-vertical-stacked>
            <h3>
              {{ barTitleData[i] | translate }}
              {{ barSentidoData[i] | translate }}
              {{ barDescripcionDocumentoData[i] | translate }}
            </h3>

          </div>
          <div class="clock-wrapper">
            <h4 style="font-size: 12px; text-align: right; padding-right: 0;">
              {{"Ultima_Actualizacion_Dash"  | translate}}
              {{listFechas[i] | date: "dd-MM-YYYY HH:mm" : agregarTimeZone() }}
            </h4>
            <mat-icon style="font-size: 14px">av_timer</mat-icon>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </mat-card>
</div>

<div
  class="cards-wrapper"
  [ngClass]="{ comunidad_is_hidden_card_wrapper: !tieneComunidad }"
  [ngClass]="expandirComunidad == false ? 'ishidden' : 'isexpanded' "
>
  <a [routerLink]="['/main/ayuda']">
    <mat-card [ngClass]="{ comunidad_is_hidden_card: !tieneComunidad }">
      <mat-icon>question_answer</mat-icon>
      <p>{{ "PREGUNTAS_FRECUENTES" | translate }}</p>
      <span class="underline"></span>
    </mat-card>
  </a>

  <a href="https://youtu.be/MZry1dnkvnU" target="blank">
    <mat-card [ngClass]="{ comunidad_is_hidden_card: !tieneComunidad }">
      <mat-icon>ondemand_video</mat-icon>
      <p>{{ "VIDEO_TUTORIALES" | translate }}</p>
      <span class="underline"></span>
    </mat-card>
  </a>

  <a [routerLink]="['/main/novedades']">
    <mat-card [ngClass]="{ comunidad_is_hidden_card: !tieneComunidad }">
      <mat-icon>new_releases</mat-icon>
      <p>{{ "NOVEDADES_DE_LA_PLATAFORMA" | translate }}</p>
      <span class="underline"></span>
    </mat-card>
  </a>
</div>
